import React, { Component } from "react"
import axios from "axios"
// import './index.css'

const GOOGLE_FORM_CONTACT_NAME = "entry.691757810"
const GOOGLE_FORM_EMAIL_ID = "emailAddress"

const CORS_PROXY = "https://cors-anywhere.herokuapp.com/"
const GOOGLE_FORM_ACTION =
  "https://docs.google.com/forms/u/0/d/e/1FAIpQLSeLeX7le8MJhLdFf5HixIy_4oZkuew7HDRFNgkKYP_6c6bJsg/formResponse"
// https://docs.google.com/forms/u/0/d/e/1FAIpQLSflU99cRTe-5CD10w0xAXPIy6n0QHnMk4mS-0gnV1mS18npqQ/viewform
// https://docs.google.com/forms/u/0/d/e/1FAIpQLSd-yYEPLLneukir3AMIeJPcpmg0KjcBiPWCJnQXEa5X7MQWpw/formResponse

class SuscribeForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      contact_name: "",
      contact_email: "",
      showForm: true,
      sendingMessage: false,
      messageSent: false,
      messageError: false,
    }
  }

  handleChange = event => {
    this.setState({ [event.target.name]: event.target.value })
  }

  handleSubmit = event => {
    event.preventDefault()
    this.setState({
      sendingMessage: true,
    })
    this.sendMessage()
  }

  handleFormToggle = () => {
    this.setState(prevState => {
      const { showForm } = prevState
      return {
        showForm: !showForm,
      }
    })
  }

  handleReturnButton = () => {
    this.setState({
      showForm: false,
      messageSent: false,
      messageError: false,
    })
  }

  sendMessage = () => {
    const formData = new FormData()
    formData.append(GOOGLE_FORM_CONTACT_NAME, this.state.contact_name)
    formData.append(GOOGLE_FORM_EMAIL_ID, this.state.contact_email)

    axios
      .post(CORS_PROXY + GOOGLE_FORM_ACTION, formData)
      .then(() => {
        this.setState({
          messageSent: true,
          sendingMessage: false,
          contact_name: "",
          contact_email: "",
        })
      })
      .catch(() => {
        this.setState({
          messageError: true,
          sendingMessage: false,
        })
      })
  }

  returnButton = () => (
    <button
      id="return-button"
      className="btn btn-default btn-xs"
      onClick={this.handleReturnButton}
    >
      Return
    </button>
  )

  render() {
    if (this.state.sendingMessage) {
      return (
        <div className="emailsuscribe">
          <div className="container">
            <h3 className="margin-bottom">
              <b>Signing up...</b>
            </h3>
          </div>{" "}
        </div>
      )
    }

    if (this.state.messageSent) {
      return (
        <React.Fragment>
          <div className="emailsuscribe">
            <div className="container msg-container">
              {/* <div className="success-message"> */}
              <h3 className="margin-bottom">
                <b>You have successfully signed up!</b>
              </h3>
              <p>
                Look out for Equity Alliance MN updates in your email inbox.
              </p>
              {/* </div> */}
              {/* {this.returnButton()} */}
            </div>
          </div>
        </React.Fragment>
      )
    }

    if (this.state.messageError) {
      return (
        <React.Fragment>
          <div className="emailsuscribe">
            <div className="container msg-container">
              {/* <div className="error-message"> */}
              <h3 className="margin-bottom">
                <b>Error!</b>
              </h3>
              <p>
                You were not able to successfully sign up. Please confirm you
                entered the right email address and try again.
              </p>{" "}
              {/* </div> */}
              {this.returnButton()}
            </div>
          </div>
        </React.Fragment>
      )
    }

    return (
      <React.Fragment>
        <div className="emailsuscribe">
          <div className="container">
            <h2 className="margin-bottom">Stay Connected</h2>
            <p>
              Get the latest about Equity Alliance MN events, services, and
              latest news.
            </p>
            <form onSubmit={this.handleSubmit} className="form-inline">
              <div className="input-group ig1">
                <input
                  type="contact_name"
                  name="contact_name"
                  id="contact_name"
                  className="form-control"
                  placeholder="Your Name"
                  size="35"
                  value={this.state.contact_name}
                  onChange={this.handleChange}
                  required
                />
                <input
                  type="contact_email"
                  name="contact_email"
                  id="contact_email"
                  className="form-control"
                  placeholder="Email Address"
                  size="35"
                  value={this.state.contact_email}
                  onChange={this.handleChange}
                  required
                />

                {/* <input
                  type="email"
                  className="form-control"
                  placeholder="email"
                  size="50"
                  required
                /> */}
              </div>
              <div className="input-group-btn ig2">
                <button type="submit" className="btn btn-danger">
                  Subscribe
                </button>
              </div>
            </form>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default SuscribeForm
