import React from "react"

import logoWhite from "../../images/eamn-icon.png"

export const NewsletterSection = () => {
  return (
    <>
      <div id="contact-us" className="section section-footer">
        <div className="container footer-top">
          <div className="col flow">
            <div className="heading-beta margin-bottom">Get In Touch</div>
            <p>
              <a href="mailto:info@ea-mn.org" className="email-address">
                info@ea-mn.org
              </a>
            </p>
            <p>
              <a href="tel:6513792675" className="email-address">
                651-379-2653
              </a>
            </p>
          </div>
          <div className="col sm-12">
            <img
              src={logoWhite}
              alt=""
              style={{
                maxWidth: "50px",
                float: "right",
                opacity: "0.5",
              }}
            />
          </div>
        </div>
        <div className="container">
          {/*<div className="col flow">*/}
          {/*  <div>*/}
          {/*    <span className="spantitle">ABOUT US</span>*/}
          {/*    <br />*/}
          {/*    <a href="/about-us/strategic-framework-and-theory-of-change">Strategic Framework</a> <br />*/}
          {/*    <a href="/about-us/staff-directory">Staff Directory</a> <br />*/}
          {/*    <a href="/about-us/joint-powers-board">Joint Powers Board</a> <br />*/}
          {/*    <a href="/about-us/funders-supporters">Funders & Supporters</a>*/}
          {/*  </div>*/}
          {/*  /!* <div className="mt-3">*/}
          {/*    10 Shop 45, <br /> Amu Timber Market, <br />*/}
          {/*    Olorunshogo, Mushin, <br />*/}
          {/*    Lagos*/}
          {/*  </div> *!/*/}
          {/*</div>*/}

          <div className="col flex flex-width-700">
            <div className="footer-nav-block flex xs-12">
              <div>
                <span className="spantitle">ABOUT US</span>
                <br />
                <a href="/about/strategic-framework-theory-of-change/">
                  Strategic Framework
                </a>{" "}
                <br />
                <a href="/team/">Staff Directory</a> <br />
                <a href="/about/joint-powers-board/">Joint Powers Board</a>{" "}
                <br />
                <a href="/about/funders-supporters/">Funders & Supporters</a>
                <br />
                <a href="/alliance-for-educational-equity/">
                  Alliance for Educational Equity
                </a>
              </div>
              {/* <div className="mt-3">
                Kilometer 53, Epe Expressway, <br /> Ibeju Lekki, <br /> Lagos
                State.
              </div> */}
            </div>
            <div className="footer-nav-block flex xs-12">
              <div>
                <span className="spantitle">PROGRAMS</span>
                <br />
                <a href="/programs/avid/">AVID Programs</a>
                <br />
                <a href="/programs/student-programs/">Student Programs</a>
                <br />
                <a href="/programs/professional-learning/">
                  Professional Learning
                </a>
                <br />
                <a href="/programs/customized-consulting/">
                  Customized Consulting
                </a>
                <br />
                <a href="/request-services/">Request Services</a>
              </div>
              {/* <div className="mt-3">
                Kilometer 53, Epe Expressway, <br /> Ibeju Lekki, <br /> Lagos
                State.
              </div> */}
            </div>
            <div className="footer-nav-block flex xs-12">
              <div>
                ​<span className="spantitle">MEMBERS</span>
                <br />
                <a href="https://www.flaschools.org/flaschools">
                  Forest Lake Area Schools
                </a>
                <br />
                <a href="https://www.isd199.org/">
                  Inver Grove Heights Community Schools
                </a>
                <br />
                <a href="https://www.isd623.org/">
                  Roseville Area Schools
                </a>{" "}
                <br />
                <a href="https://www.sspps.org/">South St. Paul Schools</a>
                <br />
                <a href="https://www.isd624.org/">
                  White Bear Lake Area Schools
                </a>
              </div>
            </div>
            <div className="flex xs-12 footer-socials">
              {/*footer-nav-block*/}
              {/*<div>*/}
              {/*  <span className="spantitle">BE SOCIAL</span>*/}
              {/*  <br />*/}
              {/*  <a href="#" className="footer-link-item ffacebook">*/}
              {/*    Facebook*/}
              {/*  </a>                <br />*/}
              {/*  <a href="#" className="footer-link-item ftwitter">*/}
              {/*    Twitter*/}
              {/*  </a>                <br />*/}
              {/*  <a href="#" className="footer-link-item finstagram">*/}
              {/*    Instagram*/}
              {/*  </a>*/}
              {/*  <br />*/}
              {/*  <a href="#" className="footer-link-item fyoutube">*/}
              {/*    Youtube*/}
              {/*  </a>              </div>*/}
              <span className="spantitle spantitle-social">SOCIAL</span>

              <div className="footer-menu-list">
                <a
                  href="https://www.facebook.com/equityalliancemn"
                  className="footer-link-item ffacebook"
                >
                  Facebook
                </a>
              </div>
              <div className="footer-menu-list">
                <a
                  href="https://twitter.com/equity_mn"
                  className="footer-link-item ftwitter"
                >
                  Twitter
                </a>
              </div>
              <div className="footer-menu-list">
                <a
                  href="https://instagram.com/equity_mn"
                  className="footer-link-item finstagram"
                >
                  Instagram
                </a>
              </div>
              {/* <div className="footer-menu-list">
                <a href="/contact/" className="footer-link-item fyoutube">
                  Youtube
                </a>
              </div> */}
            </div>
          </div>
        </div>
      </div>

      <style jsx>
        {`
          .spantitle {
            font-weight: 700;
            font-size: 1.2rem;
          }
          .spantitle-social {
            float: right;
          }
          a {
            color: #909a39;
          }
          .container {
            display: -webkit-box;
            display: -webkit-flex;
            display: -ms-flexbox;
            display: flex;
            width: 100%;
            max-width: 1200px;
            margin-right: auto;
            margin-left: auto;
          }

          .container.footer-top {
            margin: 0 auto 0px;
          }

          .col {
            margin-bottom: 32px;
            padding-right: 16px;
            padding-left: 16px;
          }

          .col.flex {
            display: -webkit-box;
            display: -webkit-flex;
            display: -ms-flexbox;
            display: flex;
          }

          .col.flex.flex-width-700 {
            -webkit-flex-basis: 700px;
            -ms-flex-preferred-size: 700px;
            flex-basis: 700px;
          }

          .col.flow {
            -webkit-box-flex: 1;
            -webkit-flex: 1;
            -ms-flex: 1;
            flex: 1;
          }

          .heading-beta {
            font-family: "Gothic A1", sans-serif;
            font-size: 74px;
            line-height: 1.15;
            font-weight: 900;
          }

          .heading-beta.margin-bottom {
            display: block;
          }

          .margin-bottom {
            display: inline-block;
            margin-bottom: 15px;
          }

          .section {
            padding-top: 80px;
            padding-bottom: 40px;
          }

          .email-address {
            border-bottom-color: rgba(0, 0, 0, 0.33);
            font-size: 23px;
            text-decoration: none;
            color: darken(white, 30%);
          }

          .footer-nav-block.flex {
            display: -webkit-box;
            display: -webkit-flex;
            display: -ms-flexbox;
            display: flex;
            padding-left: 16px;
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
            -webkit-flex-direction: column;
            -ms-flex-direction: column;
            flex-direction: column;
            -webkit-box-flex: 0;
            -webkit-flex: 0 34%;
            -ms-flex: 0 34%;
            flex: 0 34%;
          }

          .footer-link-item {
            -webkit-box-flex: 0;
            -webkit-flex: 0 0 auto;
            -ms-flex: 0 0 auto;
            flex: 0 0 auto;
            border-bottom-style: solid;
            border-bottom-color: rgba(0, 0, 0, 0.26);
            color: darken(white, 30%);
            text-decoration: none;
          }

          .footer-menu-list {
            // padding-top: 5px;
            padding-bottom: 7px;
            text-align: right;
          }

          @media (max-width: 991px) {
            .container {
              -webkit-flex-wrap: wrap;
              -ms-flex-wrap: wrap;
              flex-wrap: wrap;
            }
            .col.flow {
              -webkit-box-ordinal-group: 0;
              -webkit-order: -1;
              -ms-flex-order: -1;
              order: -1;
            }
            .footer-menu-list {
              // padding-top: 5px;
              padding-bottom: 7px;
              text-align: right;
            }
          }

          @media (max-width: 767px) {
            .container {
              display: -webkit-box;
              display: -webkit-flex;
              display: -ms-flexbox;
              display: flex;
            }
            .container.footer-top {
              margin-bottom: 80px;
            }
            .col.flex {
              -webkit-box-orient: vertical;
              -webkit-box-direction: normal;
              -webkit-flex-direction: column;
              -ms-flex-direction: column;
              flex-direction: column;
            }
            .col.flow {
              -webkit-box-ordinal-group: 2;
              -webkit-order: 1;
              -ms-flex-order: 1;
              order: 1;
            }
            .col.sm-12 {
              -webkit-box-flex: 0;
              -webkit-flex: 0 100%;
              -ms-flex: 0 100%;
              flex: 0 100%;
            }
            .footer-nav-block.flex.xs-12 {
              margin-bottom: 32px;
            }
            .footer-menu-list {
              // padding-top: 5px;
              padding-bottom: 7px;
              text-align: right;
            }

            .footer-socials {
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              padding: 0 0;
            }
          }
          @media (max-width: 479px) {
            .footer-socials {
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              padding: 0 0;
              width: 100% !important;
            }
            .footer-socials div {
              // margin: 0 1rem;
            }
            .container.footer-top {
              margin-bottom: 40px;
            }
            .col.flex {
              -webkit-flex-wrap: wrap;
              -ms-flex-wrap: wrap;
              flex-wrap: wrap;
            }
            .col.flex.flex-width-700 {
              -webkit-box-orient: horizontal;
              -webkit-box-direction: normal;
              -webkit-flex-direction: row;
              -ms-flex-direction: row;
              flex-direction: row;
            }
            .heading-beta {
              font-size: 52px;
            }
            .footer-nav-block.flex.xs-12 {
              margin-bottom: 24px;
              padding-left: 0px;
              -webkit-box-orient: vertical;
              -webkit-box-direction: normal;
              -webkit-flex-direction: column;
              -ms-flex-direction: column;
              flex-direction: column;
              -webkit-flex-basis: 100%;
              -ms-flex-preferred-size: 100%;
              flex-basis: 100%;
            }
            .footer-menu-list {
              // padding-top: 5px;
              padding-bottom: 7px;
              text-align: right;
            }
          }

          @media (max-width: 350px) {
            .footer-socials {
              display: flex;
              flex-direction: row;
              padding: 0;
            }
            .spantitle {
              display: none;
            }
          }
        `}
      </style>
    </>
  )
}
