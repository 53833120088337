import React from "react"
import UniversalLink from "./UniversalLink"
import { Link } from "gatsby"

const Footer = () => (
  <footer className="footer-area gray-bg footer-hm3 pt-90 pb-90 whitesubfooter">
    Copyright {new Date().getFullYear()}{' '} <a href="/">Equity Alliance Minnesota</a>. Powered by <UniversalLink to="http://bsmg.co">BSMG Digital</UniversalLink>.
  </footer>
)

export default Footer
