import React, { useState } from "react"
import { Link, StaticQuery, graphql } from "gatsby"

import menuOpen from "../images/menu.svg"
import menuClose from "../images/cancel.svg"
import { createLocalLink } from "../utils"
import logo from "../images/eamn-icon.png"

//
// export const menuItems = [
//   {
//     uri: "/",
//     label: "Home",
//   },
//   {
//     uri: "/about-us",
//     label: "About Us",
//   },
//   {
//     uri: "/programs",
//     label: "Programs",
//   },
//   {
//     uri: "/news",
//     label: "News",
//   },
//   {
//     uri: "/contact",
//     label: "Contact",
//   },
// ]

const MOBILE_MENU_QUERY = graphql`
  query GET_MOBILE_MENU_ITEMS {
    wpgraphql {
      menuItems(where: { location: PRIMARY }) {
        nodes {
          id
          label
          url
          childItems {
            nodes {
              id
              url
              label
              childItems {
                nodes {
                  id
                  url
                  label
                }
              }
            }
          }
        }
      }
    }
  }
`

const renderMenuItem = item => {
  let hasChild = false
  if (item.childItems && item.childItems.nodes.length) {
    hasChild = true
  }
  return (
    <li key={item.id}>
      <Link to={createLocalLink(item.url)}>{item.label}</Link>
      {hasChild && renderChildMenu(item)}
    </li>
  )
}

const renderChildMenu = item => {
  return (
    <ul className="childmenu displaymobile" id={item.id}>
      {item.childItems.nodes.map(child => renderMenuItem(child))}
    </ul>
  )
}

const MenuBtn = props => {
  return (
    <StaticQuery
      query={MOBILE_MENU_QUERY}
      render={({
        wpgraphql: {
          menuItems: { nodes: menu },
        },
      }) => {
        // console.log(menu)

        const [menuState, setMenuState] = useState(false)

        const toggleMenu = () => {
          setMenuState(!menuState)
        }
        return (
          <>
            <style jsx>
              {`
                .menu-box {
                  width: 102vw;
                  height: 100vh;
                  margin-left: -25px;
                  margin-right: -25px;
                  position: absolute;
                  top: 0;
                  background: #f8992d;
                  padding-bottom: 20px;
                }
                .menu-toggle {
                  width: 40px;
                  height: 40px;
                  display: inline-block;
                  float: right;
                  position: relative;
                  z-index: 100;
                  img {
                    width: 40px;
                  }
                }
                
                ul {
                  width: 100vw;
                  height: auto;
                  margin: 0px 10px;
                  left: 0;
                  li {
                    list-style: none;
                    font-size: 1.5em;
                    margin: 1em auto;
                  }
                }
                
                .scale-in-tr {
                  animation: scale-in-tr 0.3s
                    cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
                }
                .scale-in-tr-back {
                  animation: scale-in-tr-back 0.3s
                    cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
                }

                @keyframes scale-in-tr-back {
                  100% {
                    -webkit-transform: scale(0);
                    transform: scale(0);
                    -webkit-transform-origin: 100% 0%;
                    transform-origin: 100% 0%;
                    opacity: 1;
                    display: none;
                  }
                  0% {
                    -webkit-transform: scale(1);
                    transform: scale(1);
                    -webkit-transform-origin: 100% 0%;
                    transform-origin: 100% 0%;
                    opacity: 1;
                  }
                }
                @keyframes scale-in-tr {
                  0% {
                    -webkit-transform: scale(0);
                    transform: scale(0);
                    -webkit-transform-origin: 100% 0%;
                    transform-origin: 100% 0%;
                    opacity: 1;
                    display: block;
                  }
                  100% {
                    -webkit-transform: scale(1);
                    transform: scale(1);
                    -webkit-transform-origin: 100% 0%;
                    transform-origin: 100% 0%;
                    opacity: 1;
                  }
                }
              `}
            </style>
            <span
              className="menu-toggle d-md-none d-sm-block"
              onClick={toggleMenu}
            >
              {menuState ? (
                <img src={menuClose} className="menu-close" alt="close" />
              ) : (
                <img src={menuOpen} className="menu-open" alt="open" />
              )}
            </span>
            {/*<div*/}
            {/*  className={`menu-box d-md-none d-sm-flex flex-column justify-content-center align-items-center ${*/}
            {/*    menuState ? "scale-in-tr" : "scale-in-tr-back"*/}
            {/*  } `}*/}
            {/*>*/}
              <div
                className={`menu-box d-md-none d-sm-flex mobile-menu-box ${
                  menuState ? "scale-in-tr" : "scale-in-tr-back"
                } `}
              >

              <ul className="mobile-menu">
                <Link className="navbar-brand-mobile" to="/">
                  {/* <span> */}
                  <img src={logo} className="d-inline-block align-top" alt="" />
                  {/* </span> */}
                </Link>
                {/*{menuItems.map(item => (*/}
                {/*  <li key={item.uri}>*/}
                {/*    <Link to={item.uri} activeClassName="active">*/}
                {/*      {item.label}*/}
                {/*    </Link>*/}
                {/*  </li>*/}
                {/*))}*/}
                {menu.map(item => renderMenuItem(item))}
              </ul>
            </div>
          </>
        )
      }}
    />
  )
}

export default MenuBtn
