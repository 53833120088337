import React from "react"
import { Link } from "@reach/router"
import { useStaticQuery, graphql, navigate } from "gatsby"

import moment from "moment"
import UniversalLink from "../UniversalLink"
import { createLocalLink } from "../../utils"

function gotoPage(url) {
  navigate(url)
}

const closeAlert = () => {
console.log("This!")
  const e = document.getElementById("customAlert")
  e.className += " otherclass";
}

const AlertMsg = () => {
  const data = useStaticQuery(graphql`
query MyQuery {
  wpgraphql {
    siteOptions {
      siteOptions {
        alertMessage
        fieldGroupName
        linkText
        linkUrl {
          title
          url
        }
      }
    }
  }
}
  `)

  // console.log(data)
  // const alertMessage = data.wpgraphql.siteOptions.siteOptions.alertMessage;
  // {alertMessage ? null : 'not'}</b> logged in.
  return (
    <div id="customAlert" className="alertWrapper">
      <style jsx>{`
       
      `}</style>
      <div className="alert">
        <div className="container flexAlert">
          <div className="flexmsg">
            <span>{data.wpgraphql.siteOptions.siteOptions.alertMessage}</span>
            <Link className="alertLink" to={createLocalLink(data.wpgraphql.siteOptions.siteOptions.linkUrl.url)}>{data.wpgraphql.siteOptions.siteOptions.linkText}</Link>
            {/*<a className="alertLink" href={data.wpgraphql.siteOptions.siteOptions.linkUrl.url}>{data.wpgraphql.siteOptions.siteOptions.linkText}</a>*/}
            {/*<span>{data.wpgraphql.siteOptions.siteOptions.alertMessage}</span>*/}
          </div>
          <div className="alertClose" onClick={closeAlert}>X</div>
        </div>
      </div>
    </div>
  )
}

export default AlertMsg
