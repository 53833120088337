// import { Link } from "gatsby"
// import PropTypes from "prop-types"
import React from "react"

import { Link, StaticQuery, graphql } from "gatsby"
import { createLocalLink } from "../utils"

import MenuBtn from "./menu-btn"

import logo from "../images/eamn-icon.png"
import GoogleTranslate from "./googleTranslate"
import Menu from "./menu"

const MAIN_MENU_QUERY = graphql`
  query GET_MENU_ITEMS {
    wpgraphql {
      menuItems(where: { location: PRIMARY }) {
        nodes {
          id
          label
          url
          childItems {
            nodes {
              id
              url
              label
              childItems {
                nodes {
                  id
                  url
                  label
                }
              }
            }
          }
        }
      }
    }
  }
`

const renderMenuItem = item => {
  let hasChild = false
  if (item.childItems && item.childItems.nodes.length) {
    hasChild = true
  }
  return (
    <li key={item.id}>
       <Link to={createLocalLink(item.url)}>{item.label}</Link>
      {hasChild && renderChildMenu(item)}
    </li>
  )
}

const renderChildMenu = item => {
  return (
    <ul className={item.id}>
      {item.childItems.nodes.map(child => renderMenuItem(child))}
    </ul>
  )
}

const Header = props => {
  return (
    <StaticQuery
      query={MAIN_MENU_QUERY}
      render={({
        wpgraphql: {
          menuItems: { nodes: menu },
        },
      }) => {
        // console.log(menu)
        return (
          <div className="container">
            <nav className="navbar navbar-expand-lg navbar-light">
              <Link className="navbar-brand" to="/">
                {/* <span> */}
                <img src={logo} className="d-inline-block align-top" alt="" />
                {/* </span> */}
              </Link>

              <MenuBtn className="navbar-toggler" />

              <div
                className="collapse navbar-collapse"
                id="navbarSupportedContent"
              >
                <ul className="navbar-nav"></ul>
                {/*  mr-auto */}
                <ul className="navbar-nav float-right main-menu">
                  <style jsx>
                    {`
                      .navbar-light .nav-link.active {
                        color: blue;
                      }
                    `}
                  </style>
                   {menu.map(item => renderMenuItem(item))}
                  {/*<Menu />*/}
                </ul>
              </div>

              <GoogleTranslate />
            </nav>
          </div>
        )
      }}
    />
  )
}

export default Header
