/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import ApolloClient from "apollo-boost"
import fetch from "node-fetch"

import Header from "./header"
import SEO from "./seo"
import AOS from "aos"
import "aos/dist/aos.css"
import { NewsletterSection } from "./home/newsletter-section"

import "../styles/custom.scss"
import "./layout.scss"
import { useEffect } from "react"
import Menu from "./menu"
import SuscribeForm from "./suscribe-form"
import bg from "../images/eamn-mainbg.svg"
import Footer from "./footer"
import AlertMsg from "./alert" // Tell Webpack this JS file uses this image

const isBot = require("isbot")

export const client = new ApolloClient({
  uri: "https://admin.equityalliancemn.org/graphql",
  fetch,
})

const Layout = ({ children, seoMeta, pageName }) => {
  useEffect(() => {
    try {
      // eslint-disable-next-line no-undef
      AOS.init({
        once: true,
        disable: function() {
          if (typeof window !== "undefined") {
            if (isBot(navigator.userAgent)) {
              return true
            }
            return false
          }
          return true
        },
      })
    } catch (e) {
      //
    }
  }, [])
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
      wpgraphql {
    siteOptions {
      siteOptions {
        alertMessage
        fieldGroupName
        linkText
        linkUrl {
          title
          url
        }
      }
    }
  }
    }
    
  `)

  const alertMessage = data.wpgraphql.siteOptions.siteOptions.alertMessage

  return (
    <>
      <style jsx>{``}</style>
      <SEO title={pageName} meta={seoMeta} />
      {alertMessage!=null && <AlertMsg />}

      <Header siteTitle={data.site.siteMetadata.title} />
      <main className="mainlayout">{children}</main>
      <SuscribeForm />
      <NewsletterSection />
      <Footer />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  seoMeta: PropTypes.array,
  pageName: PropTypes.string,
}
Layout.defaultProps = {
  pageName:
    "Equity Alliance MN | Leading culturally relevant learning since 1995",
  seoMeta: [
    {
      name: "og:url",
      content: "https://www.equityalliancemn.org",
    },
    {
      name: "og:image",
      content:
        "https://res.cloudinary.com/bsmg/image/upload/v1581202929/eamn-social_ejikp9.png",
    },
  ],
}

export default Layout
